/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import SwitcherLanguage from '@common_language';
// import SwitcherCurrency from '@common_currency';
// import config from '@config';
import { ASICS_MAIN_BG } from '@theme_color';
import { useRouter } from 'next/router';

const ViewTop = (props) => {
    const {
        isLogin, t, data, handleLogout, storeConfig,
    } = props;
    // const { modules } = config;
    const route = useRouter();
    const urlPdp = route?.query?.slug?.length > 0 ? route?.query.slug[0] : null;
    return (
        <ul className="menu-top-right">
            <li className="menu-item item-language">
                <SwitcherLanguage {...props} />
            </li>
            <li className="menu-item item-trackorder">
                <Link href="/sales/order/track">
                    <a>
                        <svg width="18" height="19" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: '8px' }}>
                            {/* eslint-disable-next-line max-len */}
                            <path d="M8.25 19.1367C8.25 19.9651 7.57843 20.6367 6.75 20.6367C5.92157 20.6367 5.25 19.9651 5.25 19.1367M8.25 19.1367C8.25 18.3083 7.57843 17.6367 6.75 17.6367C5.92157 17.6367 5.25 18.3083 5.25 19.1367M8.25 19.1367H14.25M5.25 19.1367H3.375C2.75368 19.1367 2.25 18.633 2.25 18.0117V14.6372M19.5 19.1367C19.5 19.9651 18.8284 20.6367 18 20.6367C17.1716 20.6367 16.5 19.9651 16.5 19.1367M19.5 19.1367C19.5 18.3083 18.8284 17.6367 18 17.6367C17.1716 17.6367 16.5 18.3083 16.5 19.1367M19.5 19.1367L20.625 19.1367C21.2463 19.1367 21.7537 18.6324 21.7154 18.0123C21.5054 14.6047 20.3473 11.4536 18.5016 8.81956C18.1394 8.30263 17.5529 7.99446 16.9227 7.95987H14.25M16.5 19.1367H14.25M14.25 7.95987V7.00151C14.25 6.43402 13.8275 5.95393 13.263 5.89535C11.6153 5.72436 9.94291 5.63672 8.25 5.63672C6.55709 5.63672 4.88466 5.72436 3.23698 5.89535C2.67252 5.95393 2.25 6.43402 2.25 7.00151V14.6372M14.25 7.95987V14.6372M14.25 19.1367V14.6372M14.25 14.6372H2.25" stroke="#4B5563" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        {t('common:menu:trackingorder')}
                    </a>
                </Link>
            </li>
            <li className="menu-item item-help">
                <Link href="/help">
                    <a>
                        <span className="icon-asics-help" />
                        {' '}
                        {t('common:menu:help')}
                    </a>
                </Link>
            </li>
            <li className="menu-item item-store-locator">
                <Link href="/storelocator">
                    <a>
                        <span className="icon-asics-location" />
                        {' '}
                        {t('common:menu:findStore')}
                    </a>
                </Link>
            </li>

            {!isLogin ? (
                <li className="menu-item item-account-guest">
                    <span className="icon-asics-user" />
                    {urlPdp ? (
                        <Link href={`/customer/account/login/?redirect=/${urlPdp}`}>
                            <a id="header-menu-btnsign">{t('common:menu:sign')}</a>
                        </Link>
                    ) : (
                        <Link href="/customer/account/login">
                            <a id="header-menu-btnsign">{t('common:menu:sign')}</a>
                        </Link>
                    )}
                    {' '}
                    <span className="or">{t('common:menu:or')}</span>
                    {' '}
                    {urlPdp ? (
                        <Link href={`/customer/account/create/?redirect=/${urlPdp}`}>
                            <a id="header-menu-btnregister">{t('common:menu:register')}</a>
                        </Link>
                    ) : (
                        <Link href="/customer/account/create">
                            <a id="header-menu-btnregister">{t('common:menu:register')}</a>
                        </Link>
                    )}
                    {' '}
                </li>
            ) : null}

            <li className="menu-item item-account">
                {!isLogin ? (
                    t('common:header:welcome')
                ) : (
                    <>
                        <Link href="/customer/account">
                            <a>
                                <span className="icon-asics-user" />
                                {data.customer
                                    ? `${t('common:header:hi')
                                        .replace('$', `${data.customer.firstname} ${data.customer.lastname}`)}`
                                    : null}
                            </a>
                        </Link>
                        <ul className="menu-top-sub">
                            <li>
                                <Link href="/customer/account">
                                    <a>{t('common:menu:myaccount')}</a>
                                </Link>
                            </li>
                            <li>
                                <Link href="/wishlist">
                                    <a>
                                        {t('common:menu:mywishlist')}
                                        {' '}
                                        (
                                        {data.wishlist ? data.wishlist.items.length : 0}
                                        {' '}
                                        items )
                                        {' '}
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <a href="#" onClick={handleLogout}>
                                    {t('common:menu:signout')}
                                </a>
                            </li>
                        </ul>
                    </>
                )}
            </li>

            <style jsx>
                {`
                    ul {
                        margin: 0;
                        list-style: none;
                        padding: 0;
                        float: right;
                        font-size: 10px;
                        text-transform: capitalize;
                    }

                    ul.menu-top-right {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                    }

                    ul.menu-top-right > li:not(:first-child) {
                        border-left: 1px solid ${ASICS_MAIN_BG};
                    }

                    ul.menu-top-right > li > span[class*="icon-asics"],
                    ul.menu-top-right > li > a > span[class*="icon-asics"] {
                        font-size: 13px;
                        width: 18px;
                        height: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 8px;
                    }

                    ul.menu-top-right > li > span[class*="icon-asics"]:before,
                    ul.menu-top-right > li > a > span[class*="icon-asics"]:before {
                        margin-top: 1px;
                    }

                    ul.menu-top-right > li > a {
                        display: flex;
                        align-items: center;
                        border-bottom: 0 transparent !important;
                    }

                    ul.menu-top-right > li > a > span.or {
                        margin-right: 5px;
                    }

                    ul.menu-top-right ul.menu-top-sub {
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }

                    ul.menu-top-right ul.menu-top-sub li {
                        padding: 0;
                    }

                    ul.menu-top-right ul.menu-top-sub li a {
                        display: block;
                        padding: 3px;
                        border-bottom: 0 transparent !important;
                    }

                    ul.menu-top-right > li.item-account-guest + li.item-account {
                        display: none;
                    }

                    ul.menu-top-right > li.item-account-guest {
                        display: inline-flex;
                        align-items: center;
                    }

                    ul.menu-top-right > li.item-account-guest > span.or {
                        margin-left: 5px;
                        margin-right: 5px;
                    }

                    li {
                        display: inline-block;
                        padding: 0 12px;
                        position: relative;
                        font-size: 13px;
                    }

                    li:hover > ul {
                        display: block;
                    }

                    ul ul {
                        position: absolute;
                        display: none;
                        margin: 0;
                        padding: 5px 10px;
                        z-index: 999;
                        background: #fff;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                    }

                    ul ul li {
                        display: block;
                    }

                    ul ul ul {
                        position: absolute;
                        top: 0;
                        left: 100%;
                    }

                    a {
                        color: ${storeConfig && storeConfig.pwa && storeConfig.pwa.primary_color} !important;
                        text-decoration: none;
                    }

                    a:hover {
                        border-bottom: 1px dashed #fff;
                        color: #b9acac;
                    }
                `}
            </style>
        </ul>
    );
};
export default ViewTop;
